import React, { FC, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link, useLocation, useHistory } from 'react-router-dom'
import useLogoutUser from '../../hooks/authentication/useLogoutUser'
import useUser from '../../hooks/authentication/useUser'
import healthLXLogoLG from '../../assets/images/logos/HealthLXLogo_lg.png'
import fhirBanner from '../../assets/images/logos/fhir_banner.png'
// import cchp_banner from '../../assets/images/logos/cchp_banner.svg'
// import appconnect_banner from '../../assets/images/logos/appconnect_banner.svg'

import './index.css'

const Header: FC = () => {
  const [activeTab, setActiveTab] = useState('')
  const [showSecondaryNav, setShowSecondaryNav] = useState(true)
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
    case '/':
    case '/admin':
    case '/login':
      setActiveTab('home')
      break
    case '/help':
      setActiveTab('help')
      break
    case '/my-account':
      setActiveTab('my-account')
      break
    case '/register/member':
    case '/register/requestor':
    case '/register/contact':
    case '/register/validate':
    case '/register/create':
      setActiveTab('register')
      break
    default:
      setActiveTab('')
      break
    }

    setShowSecondaryNav(
      !location.pathname.includes('/developers') &&
        location.pathname !== '/app-register' &&
        !location.pathname.includes('/app-edit')
    )
  }, [location.pathname])

  const { data: user, remove: removeUser } = useUser()

  let role = ''
  if (user) {
    if (user.role.find((a: string[]) => a.includes('admin'))) {
      role = 'admin'
    }

    if (user.role.find((a: string[]) => a.includes('member'))) {
      role = 'member'
    }
  }

  const [homeLink, setHomeLink] = useState({ text: 'Home', destination: '/' })

  useEffect(() => {
    setHomeLink({
      text: role === 'admin' ? 'Admin Home' : 'Home',
      destination: role === 'admin' ? '/admin' : '/',
    })
  }, [role])

  const logoutUser = useLogoutUser()
  const history = useHistory()
  const handleLogoutUser = () => {
    logoutUser.mutate({ refreshToken: user.refresh_token })
  }

  useEffect(() => {
    if (logoutUser.isSuccess) {
      removeUser()
      history.push('/login')
    }
  }, [logoutUser.isSuccess])

  return (
    <header >
      <Link to="/">
        <div className="client_banner">
          <p>
            <Image style={{maxWidth: '350px', width: '20%', minWidth: '200px', margin: '.6%', }} src={healthLXLogoLG} className="img-fluid" alt="Health LX" />
          </p>
        </div>
        

        <div className="appconnect_banner">
          
          <Image
            style={{minWidth: '250px', width: '30%', maxWidth: '420px'}}
            src={fhirBanner}
            className="img-fluid"
            alt="App Connect"
          />
        </div>
        
      </Link>
      {showSecondaryNav && (
        <nav id="main_nav" aria-label="main navigation">
          <ul className="nav justify-content-center">
            <li className="nav-item">
              <Link
                className={`nav-link${activeTab === 'home' ? ' active' : ''}`}
                to={homeLink.destination}
              >
                {homeLink.text}
              </Link>
            </li>
            {(role === 'member' || !user) && (
              <li className="nav-item">
                <Link
                  className={`nav-link${activeTab === 'help' ? ' active' : ''}`}
                  to="/help"
                >
                  Help
                </Link>
              </li>
            )}
            {!user && (
              <li className="nav-item">
                <Link
                  className={`nav-link${
                    activeTab === 'register' ? ' active' : ''
                  }`}
                  to="/register/requestor"
                >
                  Register
                </Link>
              </li>
            )}
            {user && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link${
                      activeTab === 'my-account' ? ' active' : ''
                    }`}
                    to="/my-account"
                  >
                    My Account
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="#" onClick={handleLogoutUser}>
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  )
}

export default Header
