//! Edited

import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './index.css'

const Help: FC = () => {
  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: '820px',
      }}
    >
      <h2>Help</h2>
      <div className="support">
        <div>
          <h3>Call HealthLX</h3>
          <p>
            <a href="tel:+01-1-800-482-8010" rel="nofollow">
              (877) 539-7741
            </a>
            <br />9:00 AM - 5:00 PM CST (M-F)
          </p>
          
        </div>
        <div>
          <h3>Email HealthLX</h3>
          <p>
            Email us using our {' '}
            <a href="/contact">online contact form.</a>
          </p>
        </div>
      </div>
      <h3>What is the HealthLX Member Portal?</h3>
      <h4>Data Access For Members</h4>
      <p>Members can use the HealthLX Member Portal to view their member data online and also use their account to connect the data to commonly available health apps.</p>
      <h4>Tools for Health Plans</h4>
      <p>Health Plan Administrators can easily search and view their member FHIR data repository. The member portal also offers an easy-to-use member registration module, allowing people to register securely onlne.</p>

     

      <h3>How does the HealthLX Member Portal work?</h3>
      <p>The HealthLX Member Portal provides a link between FHIR data repositories and external apps. The Member Portal also allows the same data to be viewed online in the portal.
      </p>
      <p>For authentication, the Member Portal uses OpenID Connect (OIDC), an open authentication protocol that works on top of the OAuth 2.0 framework, a single sign-on (SSO) method for authorized apps. </p>
      <p>
        Data is exchanged between health plans and external applications using
        Health Level 7&reg; (HL7) Fast Healthcare Interoperability
        Resources&reg; (FHIR) Release 4.0.1 as the foundational standard to
        support data exchange via secure application programming interfaces
        (APIs).
      </p>

      <h3>Is the HealthLX Member Portal Secure?</h3>
      <p>
      The HealthLX Member portal is a HIPAA-compliant secure portal and data exchange.
      </p>
      <p>
      It is important to know that once a member authorizes an external application, that application has its own level of security and may not be HIPAA-compliant.
        Members should review the terms of use of any external app prior to granting access. Only grant access to trusted apps.
        HealthLX also recommends limiting the number of external apps that are authorized to limit potential exposure.
      </p>

      <h3>Why was the HealthLX Member Portal set up?</h3>
      <p>
        The HealthLX Member Portal and FHIR repository was created to meet the requirements of the Centers for
        Medicare & Medicaid Services Interoperability and Patient Access final
        rule (CMS-9115-F). This rule is meant to give patients better access to
        their health information, leading to better care and improved outcomes.
        For more information on Interoperability and Patient Access visit:{' '}
        <a
          href="https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet"
          target="_blank"
          rel="noreferrer"
        >
          https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet
        </a>
      </p>

      <h3>Can the HealthLX Member Portal be customized?</h3>

      <p>Yes, the portal branding can be customzed to include a health system logo and colors.</p>
      
    
    </Container>
  )
}

export default Help
