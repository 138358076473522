import React, { FC } from 'react'
import { Image } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import HLX_logo from '../../assets/images/logos/HLX_logo.svg'

import './index.css'

const Footer: FC = () => {
  const location = useLocation()
  const linkText = !location.pathname.includes('/developers') && location.pathname !== '/app-register' && !location.pathname.includes('/app-edit') ? 'For Application Developers' : 'App Connect for Members'
  const linkRoute = !location.pathname.includes('/developers') && location.pathname !== '/app-register' && !location.pathname.includes('/app-edit') ? '/developers' : '/login'
  return (
    <footer >
      <h3><Link to={linkRoute}>{linkText}</Link></h3>
      <nav id='secondary_nav' aria-label='secondary navigation'>
        <ul>
          <li><a href="https://www.healthlx.com/our-team" target="_blank" rel="noreferrer">About Health LX</a></li>
          <li><Link to="/developers">API</Link></li>
        </ul>
      </nav>
      <p>
        <a href="https://www.healthlx.com" target="_blank" rel="noreferrer">
          <Image className='img-fluid' src={HLX_logo} alt="HealthLX. A TESCHGlobal Company." />
        </a>
      </p>
    </footer>
  )
}

export default Footer