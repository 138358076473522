//! Edited - No Change

import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { ChangePasswordSchema } from '../../../utils/validation'

interface Props {
  initialValues: { password: string; passwordAgain: string };
  changePassword: any;
  userId: string;
  userRole: string;
}

const initialAlertState = {
  show: false,
  variant: 'success',
  message: '',
}

const ChangePassword: FC<Props> = ({
  initialValues,
  changePassword,
  userId,
  userRole,
}: Props) => {
  const [alertState, setAlertState] = useState(initialAlertState)

  useEffect(() => {
    if (changePassword.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully changed your password',
      })
    }

    if (changePassword.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to change your password: ',
      })
    }
  }, [changePassword.isSuccess, changePassword.isError])

  const formHandler = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      changePassword.mutate({
        password: values.password,
        userId,
      })
    },
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
  })

  return (
    <div className="dataContainer mb-3">
      <Row>
        <Col xs={12}>
          {userRole === 'member' && (
            <Alert variant="danger">
              <b>IMPORTANT</b>: To stop an application you&apos;ve connected to
              your data, change your password. It doesn&apos;t guarantee that
              the app will remove your data, but it will stop further
              connections.
            </Alert>
          )}
          <Alert
            show={alertState.show}
            variant={alertState.variant}
            dismissible
            onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}
          >
            {alertState.message}
            {changePassword.isError && (
              <div
                dangerouslySetInnerHTML={{
                  __html: changePassword.error?.response.text,
                }}
              />
            )}
          </Alert>
        </Col>
        <Col xs={12}>
          <Form noValidate onSubmit={formHandler.handleSubmit}>
            <Form.Group>
              <Row className="mt-3 memberRow">
                <Col md={4} sm={12}>
                  <Form.Label>New Password</Form.Label>
                </Col>
                <Col md={8} sm={12}>
                  <Form.Control
                    name="password"
                    type="password"
                    value={formHandler.values.password}
                    onChange={formHandler.handleChange}
                    onBlur={formHandler.handleBlur}
                    isInvalid={
                      !!formHandler.errors.password &&
                      !!formHandler.touched.password
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formHandler.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row className="mt-3 memberRow">
                <Col md={4} sm={12}>
                  <Form.Label>Enter Password Again</Form.Label>
                </Col>
                <Col md={8} sm={12}>
                  <Form.Control
                    name="passwordAgain"
                    type="password"
                    value={formHandler.values.passwordAgain}
                    onChange={formHandler.handleChange}
                    onBlur={formHandler.handleBlur}
                    isInvalid={
                      !!formHandler.errors.passwordAgain &&
                      !!formHandler.touched.passwordAgain
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formHandler.errors.passwordAgain}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Col xs={12} className="mt-3">
              <Button
                className="float-right memberSubmitBtn"
                type="submit"
                disabled={changePassword.isLoading}
              >
                {changePassword.isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    aria-hidden="true"
                  />
                ) : (
                  'Change'
                )}
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ChangePassword
