import React, { FC, useState } from 'react'
import { Breadcrumb, Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Coverage from '../../../components/FhirData/Coverage'
import Eobs from '../../../components/FhirData/Eobs'
import Immunizations from '../../../components/FhirData/Immunizations'
import MemberProfile from '../../../components/FhirData/MemberProfile'
import Observations from '../../../components/FhirData/Observations'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import { buildPatientName } from '../../../utils/fhirUtils/patient'
import isAuthenticated from '../../../routes/PrivateRoute'
import './index.css'
import { useEffect } from 'react'
import { getUrlParameter } from '../../../utils/helpers'

interface Props {
    height: number;
}
interface RouteParams {
  memberId: string;
  page: string | undefined;
  detailId: string | undefined;
}

const Member: FC<Props> = ({ height }: Props) => {

  const [key, setKey] = useState('Patient')
  const { memberId, page, detailId } = useParams<RouteParams>()
  const history = useHistory()

  const { data: patientData, refetch: getPatient, isLoading: patientLoading, isStale: patientIsStale } = useFhirResource(memberId, 'Patient')
  const [getEobPages, setGetEobPages] = useState('')
  const [getEobPagesOffset, setGetEobPagesOffset] = useState('')
  const { data: eobData, refetch: getPatientEobs, isStale: eobsIsStale, isFetching: fetchingEobs, isLoading: eobsLoading } = useFhirResource(memberId, 'ExplanationOfBenefit', 15, getEobPages, getEobPagesOffset, detailId)
  const handleEobNextPage = (url: string) => {
    setGetEobPages(getUrlParameter('_getpages', url))
    setGetEobPagesOffset(getUrlParameter('_getpagesoffset', url))
  }

  const { data: coverageData, refetch: getPatientCoverages, isStale: coveragesIsStale, isFetching: fetchingCoverage } = useFhirResource(memberId, 'Coverage', 25)
  const { data: immunizationData, refetch: getImmunizations, isStale: immunizationsIsStale, isFetching: fetchingImmunizations } = useFhirResource(memberId, 'Immunization', 25)
  const { data: observationData, refetch: getObservations, isStale: observationsIsStale, isFetching: fetchingObservations } = useFhirResource(memberId, 'Observation', 25)

  useEffect(() => {
    if (patientIsStale && key === 'Patient') {
      getPatient()
    }

    if (key === 'Coverage' && coveragesIsStale) {
      getPatientCoverages()
    }

    if (key === 'EOBs' && eobsIsStale) {
      getPatientEobs()
    }

    if (key === 'Immunizations' && immunizationsIsStale) {
      getImmunizations()
    }

    if (key === 'Observations' && observationsIsStale) {
      getObservations()
    }
  }, [key, patientIsStale, eobsIsStale, coveragesIsStale])

  useEffect(() => {
    if (page) {
      setKey(page)
    } else {
      setKey('Patient')
    }
  }, [page])

  const handleChangeKey = (k: string | null) => {
    setKey(k || '')

    if (k) {
      if (k === 'Patient') {
        history.push(`/patient/${memberId}`)
      } else {
        history.push(`/patient/${memberId}/${k}`)
      }
    }
  }

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        height,
        overflow: 'auto'
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/members'>Members</Breadcrumb.Item>
        <Breadcrumb.Item active={key === 'Patient'} href={`/patient/${memberId}`}>{patientData ? buildPatientName(patientData.name) : ''} {memberId}</Breadcrumb.Item>
        {
          key !== 'Patient' && (
            <>
              <Breadcrumb.Item active={key !== 'Patient' && !detailId} href={`/patient/${memberId}/${page}`}>{key}</Breadcrumb.Item>
              {
                detailId && (
                  <Breadcrumb.Item active>{detailId}</Breadcrumb.Item>
                )
              }
            </>
          )
        }
      </Breadcrumb>
      <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
        <h2>Member FHIR Data</h2>
        <Row>
          <Col sm={2}>
            <Nav variant='pills' className='flex-column'>
              <Nav.Item className='patient-pill'>
                <Nav.Link eventKey='Patient'>Member Profile</Nav.Link>
              </Nav.Item>
              <Nav.Item className='patient-pill'>
                <Nav.Link eventKey='Coverage'>Coverage</Nav.Link>
              </Nav.Item>
              <Nav.Item className='patient-pill'>
                <Nav.Link eventKey='EOBs'>EOBs</Nav.Link>
              </Nav.Item>
              <Nav.Item className='patient-pill'>
                <Nav.Link eventKey='Immunizations'>Immunizations</Nav.Link>
              </Nav.Item>
              <Nav.Item className='patient-pill'>
                <Nav.Link eventKey='Observations'>Observations</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10} className='mt-sm-0 mt-3'>
            <Tab.Content>
              <Tab.Pane eventKey='Patient'>
                <MemberProfile
                  patientData={patientData}
                  loading={patientLoading}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='Coverage'>
                <Coverage patientData={patientData} coverageData={coverageData} isFetching={fetchingCoverage}/>
              </Tab.Pane>
              <Tab.Pane eventKey='EOBs'>
                <Eobs patientData={patientData} eobData={eobData} eobDetailId={detailId} page={page} handleNext={handleEobNextPage} isFetching={fetchingEobs} />
              </Tab.Pane>
              <Tab.Pane eventKey='Immunizations'>
                <Immunizations patientData={patientData} immunizationData={immunizationData} immunizationDetailId={detailId} page={page} isFetching={fetchingImmunizations} />
              </Tab.Pane>
              <Tab.Pane eventKey='Observations'>
                <Observations patientData={patientData} observationData={observationData} observationDetailId={detailId} page={page} isFetching={fetchingObservations} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  )
}

export default isAuthenticated(Member)