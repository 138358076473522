import React, { FC, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { determineEnvironment, determineEnvironmentProvider } from '../../utils/helpers'

import './index.css'

const Developer: FC = () => {

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: 840
      }}
    >
      <h2>HealthLX Member Portal API Information</h2>
    
      <p>FHIR endpoints and server information for HealthLX below</p>
    

      <h3>Patient Access API</h3>	
    
      <p><strong>Capability Statement:</strong> https://app.healthlx.com:9090/fhir/metadata [<Link to="/developers/patientapi">JSON</Link>]</p>

      <h3>Provider Directory API</h3>	
    
      <p><strong>Capability Statement:</strong> https://app.healthlx.com:9091/fhir/metadata [<Link to="/developers/providerapi">JSON</Link>]</p>

      <h3><Link to="/app-register">App Developer Registration</Link></h3>
    </Container>
    
  )
}

export default Developer